const MARKETPLACE_BR = 'BR'
const MARKETPLACE_ID_BR = 'A2Q3Y263D00KWC'
const MARKETPLACE_CA = 'CA'
const MARKETPLACE_ID_CA = 'A2EUQ1WTGCTBG2'
const MARKETPLACE_MX = 'MX'
const MARKETPLACE_ID_MX = 'A1AM78C64UM0Y8'
const MARKETPLACE_US = 'US'
const MARKETPLACE_ID_US = 'ATVPDKIKX0DER'
const MARKETPLACE_AE = 'AE'
const MARKETPLACE_ID_AE = 'A2VIGQ35RCS4UG'
const MARKETPLACE_DE = 'DE'
const MARKETPLACE_ID_DE = 'A1PA6795UKMFR9'
const MARKETPLACE_EG = 'EG'
const MARKETPLACE_ID_EG = 'ARBP9OOSHTCHU'
const MARKETPLACE_ES = 'ES'
const MARKETPLACE_ID_ES = 'A1RKKUPIHCS9HS'
const MARKETPLACE_FR = 'FR'
const MARKETPLACE_ID_FR = 'A13V1IB3VIYZZH'
const MARKETPLACE_BE = 'BE'
const MARKETPLACE_ID_BE = 'AMEN7PMS3EDWL'
const MARKETPLACE_GB = 'GB'
const MARKETPLACE_ID_GB = 'A1F83G8C2ARO7P'
const MARKETPLACE_IN = 'IN'
const MARKETPLACE_ID_IN = 'A21TJRUUN4KGV'
const MARKETPLACE_IT = 'IT'
const MARKETPLACE_ID_IT = 'APJ6JRA9NG5V4'
const MARKETPLACE_NL = 'NL'
const MARKETPLACE_ID_NL = 'A1805IZSGTT6HS'
const MARKETPLACE_PL = 'PL'
const MARKETPLACE_ID_PL = 'A1C3SOZRARQ6R3'
const MARKETPLACE_SA = 'SA'
const MARKETPLACE_ID_SA = 'A17E79C6D8DWNP'
const MARKETPLACE_SE = 'SE'
const MARKETPLACE_ID_SE = 'A2NODRKZP88ZB9'
const MARKETPLACE_TR = 'TR'
const MARKETPLACE_ID_TR = 'A33AVAJ2PDY3EV'
const MARKETPLACE_SG = 'SG'
const MARKETPLACE_ID_SG = 'A19VAU5U5O7RUS'
const MARKETPLACE_AU = 'AU'
const MARKETPLACE_ID_AU = 'A39IBJ37TRP1C6'
const MARKETPLACE_JP = 'JP'
const MARKETPLACE_ID_JP = 'A1VC38T7YXB528'

const AVAILABLE_MARKETPLACES = {
    [MARKETPLACE_ID_BR]: MARKETPLACE_BR,
    [MARKETPLACE_ID_CA]: MARKETPLACE_CA,
    [MARKETPLACE_ID_MX]: MARKETPLACE_MX,
    [MARKETPLACE_ID_US]: MARKETPLACE_US,
    [MARKETPLACE_ID_AE]: MARKETPLACE_AE,
    [MARKETPLACE_ID_DE]: MARKETPLACE_DE,
    [MARKETPLACE_ID_EG]: MARKETPLACE_EG,
    [MARKETPLACE_ID_ES]: MARKETPLACE_ES,
    [MARKETPLACE_ID_FR]: MARKETPLACE_FR,
    [MARKETPLACE_ID_BE]: MARKETPLACE_BE,
    [MARKETPLACE_ID_GB]: MARKETPLACE_GB,
    [MARKETPLACE_ID_IN]: MARKETPLACE_IN,
    [MARKETPLACE_ID_IT]: MARKETPLACE_IT,
    [MARKETPLACE_ID_NL]: MARKETPLACE_NL,
    [MARKETPLACE_ID_PL]: MARKETPLACE_PL,
    [MARKETPLACE_ID_SA]: MARKETPLACE_SA,
    [MARKETPLACE_ID_SE]: MARKETPLACE_SE,
    [MARKETPLACE_ID_TR]: MARKETPLACE_TR,
    [MARKETPLACE_ID_SG]: MARKETPLACE_SG,
    [MARKETPLACE_ID_AU]: MARKETPLACE_AU,
    [MARKETPLACE_ID_JP]: MARKETPLACE_JP,
}

const getMarketplaceNameById = marketplaceId => {
    return AVAILABLE_MARKETPLACES[marketplaceId]
}

export {getMarketplaceNameById}

