<template>
    <div>
        <b-card>
            <div v-if="loading" class="text-center">
                <b-spinner
                    label="Loading..."
                    class="m-5"
                />
            </div>
            <form-wizard
                v-else
                color="#7367F0"
                :title="null"
                :subtitle="null"
                shape="square"
                back-button-text="Previous"
                class="mb-3"
                ref="wizard"
            >
                <template #finish>
                    <span class="hidden">Should not show finish</span>
                </template>
                <tab-content
                    title="General information"
                >
                    <b-row>
                        <b-col
                            cols="6"
                            class="mb-2"
                        >
                            Shipment name: {{ shipmentPlan.name }}
                        </b-col>
                        <b-col
                            cols="6"
                            class="mb-2"
                        >
                            Marketplace: {{ getMarketplaceNameById(shipmentPlan.marketplaceId) }}
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col
                            cols="12"
                            class="mb-2"
                        >
                            <h5 class="mb-0">
                                Label Prep Preference
                            </h5>
                            <small class="text-muted">
                                The preference for label preparation for an inbound shipment.
                            </small>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col
                            cols="6"
                            class="mb-2"
                        >
                            <b-form-group>
                                <b-form-radio
                                    v-model="shipmentPlan.labelPrepPreference"
                                    name="some-radios"
                                    value="SELLER_LABEL"
                                    :disabled="true"
                                >
                                    Seller label
                                </b-form-radio>
                                <p class="small mb-3">
                                    The seller labels the items in the inbound shipment when labels are required.
                                </p>
                                <b-form-radio
                                    v-model="shipmentPlan.labelPrepPreference"
                                    name="some-radios"
                                    value="AMAZON_LABEL_ONLY" :disabled="true"
                                >
                                    Amazon label only
                                </b-form-radio>
                                <p class="small mb-3">
                                    Amazon attempts to label the items in the inbound shipment when labels are required.
                                    If Amazon determines that it does not have the information required to successfully
                                    label an item, that item is not included in the inbound shipment plan.
                                </p>
                                <b-form-radio
                                    v-model="shipmentPlan.labelPrepPreference"
                                    name="some-radios"
                                    value="AMAZON_LABEL_PREFERRED"
                                    :disabled="true"
                                >
                                    Amazon label preferred
                                </b-form-radio>
                                <p class="small">
                                    Amazon attempts to label the items in the inbound shipment when labels are required.
                                    If Amazon determines that it does not have the information required to successfully
                                    label an item, that item is included in the inbound shipment plan and the seller
                                    must label it.
                                </p>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col
                            cols="6"
                            class="mb-2"
                        >
                            Status:
                            <b-badge v-if="shipmentPlan.status === 1" variant="success">
                                Created
                            </b-badge>
                            <b-badge v-else variant="warning">
                                Pending
                            </b-badge>
                        </b-col>
                    </b-row>
                </tab-content>
                <tab-content
                    title="Items"
                >
                    <b-row>
                        <b-col
                            cols="12"
                            class="mb-2"
                        >
                            <div v-if="loading" class="text-center">
                                <b-spinner
                                    label="Loading..."
                                    class="m-5"
                                />
                            </div>
                            <b-table v-else-if="shipmentPlan.items.length > 0" :items="shipmentPlan.items" :fields="itemsTableFields">
                                <template #cell(imageUrl)="data">
                                    <img :src="data.item.imageUrl" :alt="data.item.title" class="img-fluid" width="100">
                                </template>
                            </b-table>
                            <template v-else>
                                <h5>Looks like there are no items in the shipment. Most likely the shipment creation
                                    will fail</h5>
                            </template>
                        </b-col>
                    </b-row>
                </tab-content>
                <tab-content
                    title="Address"
                >
                    <b-row>
                        <b-col
                            cols="12"
                            class="mb-2"
                        >
                            <h5 class="mb-0">
                                Address
                            </h5>
                            <small class="text-muted">
                                The address from which the inbound shipment will be sent.
                            </small>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="6" class="mb-2">
                            Name: {{ shipmentPlan.address.name }}
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="6" class="mb-2">
                            Address line 1: {{ shipmentPlan.address.addressLine1 }}
                        </b-col>
                        <b-col cols="6" class="mb-2">
                            Address line 2: {{ shipmentPlan.address.addressLine2 }}
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="6" class="mb-2">
                            City: {{ shipmentPlan.address.city }}
                        </b-col>
                        <b-col cols="6" class="mb-2">
                            State or Province: {{ shipmentPlan.address.stateOrProvinceCode }}
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="6" class="mb-2">
                            Country: {{ shipmentPlan.address.countryCode }}
                        </b-col>
                        <b-col cols="6" class="mb-2">
                            Postal code: {{ shipmentPlan.address.postalCode }}
                        </b-col>
                    </b-row>
                </tab-content>
            </form-wizard>
        </b-card>
    </div>
</template>

<script>
import Axios from "axios"

require('@core/scss/vue/libs/vue-wizard.scss')

import {
    BCard,
} from 'bootstrap-vue'
import {FormWizard, TabContent} from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import {
    BRow,
    BCol,
    BTable,
    BSpinner,
    BFormGroup,
    BFormRadio,
    BBadge,
} from 'bootstrap-vue'
import {getMarketplaceNameById} from "@/services/AmazonMarketplaces"

export default {
    name: 'inventory.createAmazonShipment',
    components: {
        BCard,
        FormWizard,
        TabContent,
        BRow,
        BCol,
        BTable,
        BSpinner,
        BFormGroup,
        BFormRadio,
        BBadge,
    },
    data() {
        return {
            getMarketplaceNameById,
            itemsTableFields: [
                {
                    key: 'imageUrl',
                    label: 'Image',
                    sortable: false,
                },
                {
                    key: 'title',
                    label: 'Title',
                    sortable: true,
                },
                {
                    key: 'sellerSku',
                    label: 'Amazon SKU',
                    sortable: true,
                },
                {
                    key: 'asin',
                    label: 'ASIN',
                    sortable: true,
                },
                {
                    key: 'quantity',
                    label: 'Quantity',
                    sortable: true,
                },
            ],
            loading: false,
            shipmentPlan: {
                id: 0,
                name: '',
                address: {
                    name: '',
                    addressLine1: '',
                    addressLine2: '',
                    city: '',
                    stateOrProvinceCode: '',
                    countryCode: '',
                    postalCode: '',
                },
                labelPrepPreference: '',
                marketplaceId: '',
                items: [],
                status: 0,
            },
        }
    },
    async mounted() {
        await this.loadShipmentData()
        this.$refs.wizard.activateAll()
    },
    methods: {
        async loadShipmentData() {
            this.loading = true
            try {
                this.shipmentPlan = await this.getShipmentData()
            } finally {
                this.loading = false
            }
        },
        async getShipmentData() {
            const response = await Axios({
                url: `${process.env.VUE_APP_API_HTTP_ROOT}shipment-plans/view-amazon-shipment-plan/${this.$route.params.id}`,
                method: 'GET',
            })

            return response.data.data
        },
    },
}
</script>

<style scoped>


</style>